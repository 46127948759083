.PaginationPager{
    display: block;
    margin: 1em auto 0 auto;

  .PaginationPager__row-count{
    font-size: .75em;
    text-align: center;
  }

  .PaginationPager__group{
    justify-content: center;
  }
}

