@import './../../assets/styles/variables';

.LoginPage{
  .login{

  }

  .login__title{

  }

  .login__header{
    max-width: 100%;
  }

  .login__form{

  }

  .login__option-container{
    text-align: center;
    margin-top: 1em;
  }

  .login__option{
    color: $primary;
    text-decoration: underline;
  }
}