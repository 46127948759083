.async-content__loader{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25vh;
  text-align: center;
}

.async-content__error{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 25vh;
  text-align: center;
}