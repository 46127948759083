@import './../../assets/styles/variables';

.TopBar{
  z-index: 100;

  &.topbar{
    background-color: $primary;
    color: $white;

    @media(max-width: map-get($grid-breakpoints, "md")){
      border-bottom-left-radius: $topbar-height / 2;
      border-bottom-right-radius: $topbar-height / 2;
      margin-top: -$topbar-height;
      padding-top: $topbar-height;
    }
  }

  .topbar__main-content{
    background-color: $primary;
    height: $topbar-height;
    display: flex;
    justify-content: stretch;
    line-height: $topbar-height;
    padding: 3px 1.5em;
    align-items: center;
    overflow: hidden;

    @media(max-width: map-get($grid-breakpoints, "md")){
      padding: 0;
      border-bottom-left-radius: $topbar-height / 2;
      border-bottom-right-radius: $topbar-height / 2;

      position: fixed;
      left:0;
      top:0;
      right:0;
      z-index: 5;
    }
  }

  .topbar__responsive-content{
    display: none;
    z-index: 4;

    @media(max-width: map-get($grid-breakpoints, "sm")){
      display: flex;
      justify-content: center;
      padding: 1em;
    }
  }

  .topbar__left-buttons-container{
    display: none;

    @media(max-width: map-get($grid-breakpoints, "xl")){
      display: unset;
    }
  }

  .topbar__right-buttons-container{

  }

  .topbar__title-container{
    flex-grow: 1;

    @media(max-width: map-get($grid-breakpoints, "xl")){
      text-align: center;
    }

    @media(max-width: 300px){
      font-size: .75em;
    }
  }

  .topbar__title{
    margin: 0;
    font-size: 1.2em;
    font-weight: bolder;
  }

  .topbar__icon{
    cursor: pointer;
    color: $white;
    font-size: 30px;
    display: block;
    width: $topbar-height;
    text-align: center;

    &:hover{
      background-color: darken($primary, 10%);
    }
  }

  .topbar__icon--notifications{

  }

  .topbar__icon--notifications-new{
    position: relative;

    &:after{
      position: absolute;
      content: '';
      background-color: #E02020;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: calc(50% - .5em);
      right: calc(50% - .5em);
    }
  }

  .TopBar__impersonating-bar{
    background-color: #F6F6F6;;
    display: flex;
    justify-content: center;
  }

  .TopBar__impersonating-bar-main{
    background-color: #cfcfcf;
    color: white;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;

    margin: 0 15px;
    padding: .3125rem 1em;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }

  .TopBar__impersonating-bar-back-btn{
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    font-size: 18px;
    padding: 0 10px;
    text-align: center;
    border-radius: 4px;
    margin: 0 15px;
    cursor: pointer;
  }
}