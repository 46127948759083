.ImagePicker{
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;

  background-size: cover;
  background-position: center center;

  &:hover{
    text-decoration: inherit;
    color: inherit;
  }

  text-align: center;
  padding: 20px;
  display: block;
  width: 100%;
  border: 1px solid rgba(200, 200, 200, .5);
  border-radius: 30px;

  .ImagePicker__error{
    margin-top: 20px;
    color: red;
  }

  .ImagePicker__label{
    font-weight: bold;
    margin-bottom: .5em;
    text-shadow: 0 0 3px lightgrey;
  }

  .ImagePicker__icon{
    border-radius: 50%;
    color: white;
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 30px;
    line-height: 25px;
    box-shadow: 0 0 3px lightgrey;
  }
}