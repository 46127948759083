.OnboardingHeader{
  text-align: center;
  margin: 1em auto;

  .OnboardingHeader__title{
    font-weight: bolder;
  }

  .OnboardingHeader__description{

  }
}