.EmptyLayout{
  display: block;
  margin: auto;
  max-width: 430px;
  padding: 50px 15px;

  @media(max-width: 430px){
    max-width: 100%;
    padding: 12px;
  }
}