.PersonalDataMatiVerificationForm{
  text-align: center;

  .VerificationSpinner{
    display: block;
    margin: 1em auto;
  }
}

.PersonalDataMatiVerificationForm__mati_container{
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}