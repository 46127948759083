.SocialMediaInput{
  border-radius: 10px;
  position: relative;
  padding-left: 40px;

  .SocialMediaInput__side_content{
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 5px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  .SocialMediaInput__icon{
    width: 30px;
    height: 30px;
  }

  .SocialMediaInput__input_container{
    input {
      text-align: center;
      padding: 0;
      margin: 0;
      border-bottom: none;
      color: white;
      font-weight: bolder;
      height: 40px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      padding-right: 40px;

      &::placeholder{
        color: lightgrey;
      }

      &:hover, &:active, &:focus{
        background-color: inherit;
      }
    }
  }
}