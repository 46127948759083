@import './../../assets/styles/variables';

.ChangePasswordModal{
  &.change-password{

  }

  .change-password__feedback{
    text-align: center;
  }

  .change-password__feedback--success{
    color: $black;
  }

  .change-password__feedback--error{
    color: $red;
  }
}