.OnboardingBackBtn{
  color: inherit;
  background-color: transparent;
  outline: none;
  width: auto;
  height: auto;
  border: none;
  font-size: 30px;

  &:hover{
    background-color: transparent;
    color: inherit;
  }
}