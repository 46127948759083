.BusinessDataForm{
  .BusinessDataForm__address{
    width: 90%;
    margin: auto;
  }

  .BusinessDataForm__search_container{
    text-align: center;
    margin-top: .5em;
    margin-bottom: .5em;
  }
}