@import '../../assets/styles/variables';

@keyframes spinner-animation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Spinner{
  position: relative;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid $primary; /* Blue */
  border-radius: 50%;
  animation: spinner-animation .75s linear infinite;

  &.Spinner--small{
    border-width: 4px;
  }
}