.PersonalDataRenaperVerificationForm{
  .PersonalDataRenaperVerificationForm__photo_picker_img{
    max-width: 33%;
    height: auto;
    margin: 30px 0;
  }

  .PersonalDataRenaperVerificationForm__photo_picker_header{
    padding: 0 15px;
  }

  .PersonalDataRenaperVerificationForm__image_picker{
    margin: 40px 0;
  }
}