@import './../../assets/styles/variables';

.VerifyEmailPage{
  text-align: center;

  .VerifyEmailPage__success-content{
    margin-top: 2em;
  }

  .VerifyEmailPage__success-content-icon{
    margin-bottom: 1em;
    margin-top: 1em;
  }
}