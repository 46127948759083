.Paginator{
    margin-top: 1em;

  .Paginator__row-count{
    font-size: .75em;
    text-align: center;
  }

  .Paginator__group{
    justify-content: center;
  }
}

