.card-prode {
    height: 7830px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-image: url('../../assets/images/worldcup/prode-d.png?auto=format');
    background-repeat: no-repeat;
    background-size: 100%;
    max-width: 540px;
    margin: auto;
}

.card-prode a {
    position: relative;
    background: #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
    border-radius: 30px;
    cursor: pointer;
    top: 542px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: #125483;
    height: 54px;
    width: 267px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 2;

    text-decoration: none;
}

@media (max-width: 991px ) {
    .card-prode a {
        top: 390px;
    }
}

@media (max-width: 480px) {
    .card-prode {
        width: 323px;
        height: 284px;
        background-image: url('../../assets/images/worldcup/prode-m.png?auto=format');
        background-size: 100% auto;
    }

    .card-prode a {
        font-size: 16px;
        top: 325px;
        width: 155px;
        height: 33px;
    }
}
