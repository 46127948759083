@import './../../assets/styles/variables';

.AlertContainer{
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  max-width: 400px;
  padding-right: 15px;
}
