@import '../../assets/styles/variables';

.UsersIndexPage{
  padding: 10px 0;

  @media(max-width: map-get($grid-breakpoints, "sm")){
    padding-top: 30px;
  }

  .UserRow{
    display: block;
    color: $black;
    font-size: .9em;
    margin-top: 20px;

    &:hover{
      text-decoration: none;
    }
  }

  .UserRow__inner{
    border: 1px solid rgba(83, 82, 86, 0.2);
    padding: 15px;
    border-radius: 20px;
  }

  .UserRow__line{
    margin: 0;
    line-height: 1em;
    color: #334048;
    margin-bottom: 5px;
  }

  .UserRow__line--title{
    color: black;
    margin-bottom: .5em;
    font-weight: bolder;

    font-size: 1em;
    line-height: 1.2em;
  }
}