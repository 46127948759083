@import './../../assets/styles/variables';

.RegisterPage{
  .register{

  }

  .register__title{

  }

  .register__header{
    max-width: 100%;
  }

  .register__form{

  }

  .register__option-container{
    text-align: center;
    margin-top: 1em;
  }

  .register__option{
    color: $primary;
    text-decoration: underline;
  }
}